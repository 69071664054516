.contact__container {
  display: grid;
  place-items: center;
}

.p__contact {
  color: white;
}

.contact__wrapper {
  width: fit-content;
  display: flex;
  gap: 2rem;
}

.contact__wrapper a {
  background: var(--color-primary);
  color: var(--color-secondary);
  font-size: 2rem;
  border: 2px solid transparent;
  transition: var(--transition);
  padding: 2rem;
  border-radius: 1.5rem;
}

.btn-contact {
  background: var(--color-primary);
  font-size: 2rem;
  border: 2px solid transparent;
  transition: var(--transition);
  padding: 2rem;
  border-radius: 1.5rem;
}

.btn-contact:hover {
  background: transparent;
  border-color: var(--color-gray-100);
}

.contact__wrapper a:hover {
  background: transparent;
  border-color: var(--color-gray-100);
}
@media screen and (max-width: 900px) {
  .contact__wrapper {
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 600px) {
  .contact__wrapper {
    gap: 1.5rem;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .contact__wrapper a {
    padding: 1rem;
    font-size: 1.2rem;
    border-radius: 1rem;
  }
}
