.services__page {
  width: 100%;
  position: relative;
  /* top: 120px; */
  scroll-snap-align: 0;
  margin-top: 0.5rem;
}

.services__page-container {
  display: grid;
  grid-template-columns: 50% 40%;
  gap: 10%;
  margin-left: 2rem;
  margin-right: 3rem;
}

.services__page-img img {
  width: 70%;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 0 50% 50% 0;
  overflow: hidden;
  opacity: 0.3;
  height: min-content;
}

.services__page-content h2 {
  margin-bottom: 2rem;
}

.services__page-content p {
  margin-bottom: 1rem;
  color: white;
}

@media screen and (max-width: 1024px) {
  .services__page-img {
    width: 60%;
  }

  .services__page-img img {
    margin-top: 0;
    padding-top: 0;
    border-radius: 0 0 50% 50%;
    width: 100%;
    height: 8rem;
  }

  .services__page-content h2 {
    margin-bottom: 1.2rem;
  }
  .services__page-container {
    grid-template-columns: 1fr;
    gap: 1rem;
    place-items: center;
  }
}

@media screen and (max-width: 600px) {
  .services__page-img img {
    width: 80%;
    margin-top: 0;
    border-radius: 0 0 50% 50%;
    margin-inline: auto;
  }

  .services__page-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
}
