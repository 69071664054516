*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --primary-hue: 239;
  --gray-hue: 240;
  --color-primary: hsl(var(--primary-hue), 10%, 25%);
  --color-primary-variant: hsl(var(--primary-hue), 9%, 29%);
  --color-secondary: #ff7316;
  --color-gray-100: hsl(var(--gray-hue), 47%, 94%);
  --color-gray-200: hsl(var(--gray-hue), 26%, 75%);
  --color-gray-300: hsl(var(--gray-hue), 24%, 64%);
  --color-gray-400: hsl(var(--gray-hue), 16%, 41%);
  --color-gray-500: hsl(var(--gray-hue), 44%, 25%);
  --color-gray-600: hsl(var(--gray-hue), 48%, 15%);

  --container-width-lg: 80%;
  --container-width-md: 90%;

  --transition: all 500ms ease;
}

body {
  font-family: 'Montserrat', 'sans-serif';
  color: var(--color-secondary);
  line-height: 1.7;
  overflow-x: hidden;
  background: var(--color-gray-400) url('./img/texture.png');
}

.container {
  width: var(--container-width-lg);
  max-width: 1920px;
  margin-inline: auto;
}

h1,
h2,
h3,
h4,
h5 {
  line-height: 1.2;
  color: var(--color-secondary);
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

a {
  color: var(--color-gray-100);
}

img {
  display: block;
  object-fit: cover;
  width: 100%;
}

.btn {
  color: var(--color-secondary);
  width: fit-content;
  margin: 0 auto;
  padding: 0.9rem 2rem;
  background: var(--color-primary);
  border-radius: 1.5rem;
  transition: var(--transition);
}

.btn:hover {
  background: var(--color-gray-300);
  color: var(--color-primary);
}

.btn.lg {
  padding: 1.2rem 3rem;
  border-radius: 2rem;
  font-size: 1.1rem;
}

.btn.sm {
  padding: 0.4rem 1.2rem;
  font-size: 0.9rem;
}

.btn.sm:hover {
  background: var(--color-gray-300);
  color: var(--color-primary);
}

/*---reusable sections ---*/
section {
  margin-top: 3rem;
}

.section__head {
  display: flex;
  align-items: center;
  gap: 1rem;
}
.section__head span {
  background: var(--color-gray-500);
  padding: 0.8rem;
  border-radius: 1rem;
  color: var(--color-secondary);
}

.card {
  background: var(--color-primary-variant);
  border: 2px solid transparent;
  text-align: center;
  padding: 3rem 2rem;
  border-radius: 0 3rem 0 3rem;
  transition: var(--transition);
}

.card:hover {
  background: transparent;
  border-color: var(--color-primary);
  cursor: default;
  color: var(--color-gray-600);
}

.card img {
  width: 18rem;
  height: 18rem;

  /* background: var(--color-primary); */
  color: var(--color-gray-100);
  font-size: 1.5rem;
  padding: 0.2rem;
  display: grid;
  place-items: center;
  margin-bottom: 1.5rem;
  margin-inline: auto;
  border-radius: 2rem;
  transition: var(--transition);
}

.card:hover img {
  background: var(--color-gray-200);

  color: var(--color-gray-600);
}

.card:hover h4 {
  color: var(--color-gray-800);
}

.card small {
  margin-top: 1rem;
  display: block;
  font-weight: 300;
}

.modal {
  display: flex;
  flex-direction: column;
  margin-top: 6rem;
  background: var(--color-primary-variant);
  border: 2px solid transparent;
  text-align: center;
  margin-left: 2rem;
  margin-right: 2rem;
  padding: 1rem 1rem;
  border-radius: 3rem;
  transition: var(--transition);
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.player-wrapper {
  position: relative;
  /*padding-top: 56.25%;  720 / 1280 = 0.5625 */
  height: 23rem;
  width: 48rem;
  margin-bottom: 2rem;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  margin-top: 5rem;
  height: 12rem;
  overflow: hidden;
  border-bottom: 5px solid var(--color-gray-200);
}

.header__container {
  width: 100%;
  height: 100%;
  position: relative;
  display: grid;
  place-items: center;
  background: rgb(82, 81, 81);
}

.header__container-bg {
  position: absolute;
  width: 100%;
}

.header__container-bg img {
  opacity: 0.4;
}

.header__content {
  position: relative;
  text-align: center;
  width: 50%;
  margin: 0 auto;
  color: var(--color-gray-100);
}

.header__content h2 {
  color: var(--color-secondary);
  margin-bottom: 1rem;
}

.header__content p {
  color: black;
  font-weight: 600;
}

/** General styles for media queries (medium screens)**/
@media screen and (max-width: 1024px) {
  .container {
    width: var(--container-width-md);
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }

  .header__content {
    width: 76%;
  }

  .player-wrapper {
    position: relative;
    /*padding-top: 56.25%;  720 / 1280 = 0.5625 */
    height: 280px;
    width: 700px;

    margin-bottom: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }
}

@media screen and (max-width: 900px) {
  .player-wrapper {
    position: relative;
    /*padding-top: 56.25%;  720 / 1280 = 0.5625 */
    height: 200px;
    width: 700px;

    margin-bottom: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.6rem;
  }
}
/** General styles for media queries (small screens)**/
@media screen and (max-width: 600px) {
  h1 {
    font-size: 2.2rem;
    line-height: 1.3;
  }

  section {
    margin-top: 7rem;
  }

  .header {
    height: fit-content;
  }

  .header__content {
    width: var(--container-width-md);
    padding: 2rem 0;
  }

  .header__content p {
    font-size: 0.85rem;
  }

  .player-wrapper {
    position: relative;
    /*padding-top: 56.25%;  720 / 1280 = 0.5625 */
    height: 180px;
    width: 700px;
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 400px) {
  .player-wrapper {
    position: relative;
    /*padding-top: 56.25%;  720 / 1280 = 0.5625 */
    height: 150px;
    width: 500px;
    margin-bottom: 1rem;
  }
}
