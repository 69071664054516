.about__section-image-l {
  border-radius: 50% 0% 50% 0%;
  overflow: hidden;
}
.about__section-image-r {
  border-radius: 0% 50% 0% 50%;
  overflow: hidden;
}

.about__section-content h1 {
  margin-bottom: 2rem;
}

.about__section-content p {
  margin-bottom: 1rem;
  color: white;
}

.about__story-container,
.about__mission-container {
  display: grid;
  grid-template-columns: 40% 50%;
  gap: 10%;
  margin-left: 2rem;
  margin-right: 2rem;
}

.about__vision-container {
  display: grid;
  grid-template-columns: 50% 40%;
  gap: 10%;
  margin-left: 2rem;
  margin-right: 2rem;
}

@media screen and (max-width: 1024px) {
  .about__section-image-l,
  .about__section-image-r {
    width: 60%;
  }

  .about__section-content h1 {
    margin-bottom: 1.2rem;
  }

  .about__story-container,
  .about__mission-container,
  .about__vision-container {
    grid-template-columns: 1fr;
    gap: 3rem;
    place-items: center;
  }

  .about__section-image-r {
    grid-row: 1;
  }
}

@media screen and (max-width: 600px) {
  .about__section-image-l,
  .about__section-image-r {
    width: 80%;
    margin-inline: auto;
  }

  .about__story-container,
  .about__mission-container,
  .about__vision-container {
    grid-template-columns: 1fr;
    gap: 2rem;
  }

  .about__section-image-r {
    grid-row: 1;
  }
}
