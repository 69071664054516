.main__header {
  width: 100vw;
  height: calc(100vh - 3rem);
  display: grid;
  place-items: center;
  margin-top: 3rem;
}

.main__header-container {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-content: space-between;
  gap: 6rem;
}

.main__header-left {
  margin-top: -3rem;
}

.main__header-left h4 {
  margin-bottom: 1rem;
  font-weight: 400;
  color: white;
}

.main__header-left p {
  margin: 1rem 0 3.5rem;
  font-size: 1.1rem;
  color: white;
}

/**test with media*/
@media screen and (max-width: 1024px) {
  .btn.lg {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;
  }

  .btn.lg:hover {
    background: var(--color-gray-300);
    color: var(--color-primary);
  }
}

/*--------services sections-------*/
.services {
  margin-top: 4rem;
}

.services__wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 4rem;
}

.services__services {
  margin-top: 2.5rem;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}
/* .services__services a {
  background: var(--color-gray-300);
  color: var(--color-primary);
} */
.services__services:hover a {
  color: var(--color-secondary);
}

/*--------works sections-------*/
.works {
  margin-top: 4rem;
}

.works__title {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.workList {
  background: var(--color-primary);
  padding: 1rem;
  border-radius: 20px;
  border: 2px solid var(--color-primary);
}

.workList.active {
  background: var(--color-gray-300);
  color: var(--color-primary);
  font-weight: 700;
}

.works__work {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 3rem;
  margin-top: 4rem;
}
.works__wrapper {
  margin-top: 4rem;
}

.works__work-items {
  margin-top: 2.5rem;
  /* display: flex; */
  align-items: center;
  justify-content: center;
}

.works__work {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  margin-top: 4rem;
}

/*--------footer-------*/

footer {
  background: var(--color-primary);
  margin-top: 7rem;
  padding-top: 7rem;
  font-size: 0.9rem;
  color: var(--color-secondary);
}

.footer__container {
  display: grid;
  grid-template-columns: 26rem 1fr 1fr 1fr;
  gap: 6rem;
}

.footer__container article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.7rem;
}

.footer__container article p {
  margin-top: 0.5rem;
}

.footer__container article h4 {
  margin-bottom: 0.6rem;
  font-size: 1rem;
}

.footer__socials {
  margin-top: 1rem;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.footer__socials a {
  background: var(--color-gray-300);
  padding: 0.7rem;
  border: 1px solid transparent;
  border-radius: 0.6rem;
  transition: var(--transition);
}

.footer__socials a svg {
  color: var(--color-gray-600);
}

.footer__socials a:hover {
  border-color: var(--color-gray-300);
  background: transparent;
}

.footer__copyright {
  color: var(--color-gray-100);
  text-align: center;
  padding: 1.5rem 0;
  border-top: 2px solid var(--color-gray-300);
  margin-top: 5rem;
}

/** Homestyles for media queries (medium screens)**/
@media screen and (max-width: 1024px) {
  /* main header section */
  .main__header {
    height: fit-content;
    padding: 12rem 0;
  }
  .main__header-container {
    gap: 0;
  }

  /* services */
  .services__wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .card img {
    width: 15rem;
    height: 15rem;
  }

  .works__work {
    grid-template-columns: 1fr 1fr;
  }

  .footer__container {
    grid-template-columns: 1fr 1fr;
    gap: 4rem;
  }
}

/** Homestyles for media queries (small screens)**/
@media screen and (max-width: 600px) {
  /* main header section */
  .main__header {
    margin-top: 0;
    height: 100vh;
    padding: 12rem 0 0;
  }

  .main__header-image {
    display: none;
  }

  .main__header-container {
    grid-template-columns: 1fr;
  }

  /* services */
  .services__wrapper {
    grid-template-columns: 1fr;
    gap: 2rem;
    margin-top: 3rem;
  }
  .services__services {
    width: 84%;
    margin: 0 auto;
  }

  .card img {
    width: 15rem;
    height: 15rem;
  }

  .works__work {
    grid-template-columns: 1fr;
  }

  .workList {
    padding: 0.5rem;
  }

  .workList.active {
    font-weight: 500;
  }

  footer {
    margin-top: 7rem;
  }

  .footer__container {
    grid-template-columns: 1fr;
    gap: 3rem;
  }

  .footer__container article {
    align-items: center;
  }

  .footer__container article p {
    text-align: center;
  }
}
